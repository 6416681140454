// eslint-disable-next-line import/no-unresolved, import/extensions
export const getCookie = cname => {
	const name = cname + '=';
	const decodedCookie = decodeURIComponent(document.cookie);
	const ca = decodedCookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return null;
};

export const setCookie = (name, value, expire = 0, path = '/') => {
	const expires = expire ? `expires=${new Date(expire).toUTCString()};` : '';
	document.cookie = `${name}=${value};${expires}path=${path}`;
};
